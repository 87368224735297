<template>
  <div class="sells q-px-md">

    <!-- FAIXA DE CONTROLE -->
    <div class="btns q-mt-md row justify-center">
      <q-btn no-caps icon="fas fa-file-invoice-dollar" label="Incluir Despesa" @click="onAdd" style="width: 280px; background-color: var(--primary)" class="q-mb-md q-mx-md text-white" />

      <q-btn icon="event" label="Selecionar Período" no-caps style="width: 280px; background-color: var(--primary)" class="q-mb-md q-mx-md text-white">
        <q-popup-proxy transition-show="scale" transition-hide="scale" >
          <q-date v-model="datePicker" range :locale="ptBR">
            <q-btn color="negative" label="Cancelar" flat v-close-popup />
            <q-btn color="primary" label="Confirmar" flat v-close-popup @click="onDate" />
          </q-date>
        </q-popup-proxy>
      </q-btn>
    </div>

    <!-- TABELA DE ACOMPANHAMENTO -->
    <div class="table">
      <q-table
        dense
        class="myTable"
        :rows="rows"
        :columns="cols"
        row-key="name"
        :rows-per-page-options="[10, 20, 50, 100, 99999]"
        rows-per-page-label="Linhas por página: "
        :filter="filter"
        no-results-label="Sem dados a serem mostrados"
        no-data-label="Sem dados a serem mostrados"
        @row-click="onEditRow"
      >
        <template v-slot:top-left>
          <q-input filled dense dark v-model="filter" placeholder="Pesquisar" style="width: 150px">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template v-slot:top-right>
          <q-btn
            flat
            rounded
            style="background-color: var(--verdeclaro); color: white"
            icon="file_download"
            @click="exportTable(rows)"
          />
        </template>
      </q-table>
    </div>

    <!-- MODAL PARA INSERÇÃO DE NOVOS SEGUIDORES -->
    <q-dialog v-model="modalExpenses" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div v-if="editRow" class="title text-h5 text-bold text-center text-verde q-mb-md">
          EDITANDO DESPESAS
        </div>
        <div v-else class="title text-h5 text-bold text-center text-verde q-mb-md">
          INCLUSÃO DE NOVA DESPESA
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <q-scroll-area style="width: 100%; height: 60vh;" v-show="rebuild">
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="twoFields" outlined v-model="expense" type="text" label="Despesa" />
            <q-input class="twoFields" outlined v-model="type" type="text" label="Tipo" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="threeFields" outlined v-model="value" type="number" label="Valor Despesa" prefix="R$" placeholder="0" @change="onDesp" />
            <q-input class="threeFields" outlined v-model="dtexp" type="date" stack-label label="Data da Despesa" />
            <!-- <q-input class="threeFields" outlined v-model="dtexp" label="Data da Despesa" >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="dtexp" :locale="ptBR" mask="DD/MM/YYYY" today-btn >
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Confirmar" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input> -->
            <q-input class="threeFields" outlined v-model="responsible" type="text" label="Responsável" />
          </div>
        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Cancelar" @click="onCancel" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="primary" label="Salvar" @click="onSave" />
          <div class="q-mx-md" />
          <q-btn v-show="editRow" no-caps color="negative" label="Excluir" @click="onDelete" />
        </div>
      </div>
    </q-dialog>

  </div>
</template>

<script>
import DateFormat from '../../helpers/DateFormat';
// import dateToString from '../../../helpers/dateToString.js';
import locale from 'quasar/lang/pt-BR';
import { mapActions } from 'vuex';
import { Dialog } from 'quasar';
import { defaultColor } from '../../helpers/defaultColor';
import DateEnglish from '../../helpers/DateEnglish';

export default {
  data() {
    return {
      enterprise: localStorage.getItem('enterprise'),
      modalExpenses: false,
      filter: null,
      cols: [
        { name: 'expense',      align: 'center', label: 'Despesa',      field: 'expense',     sortable: true },
        { name: 'type',         align: 'center', label: 'Tipo',         field: 'type',        sortable: true },
        { name: 'value',        align: 'center', label: 'Valor',        field: 'value',       sortable: true,
          format: val => parseFloat(val).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },
        { name: 'dtexp',         align: 'center', label: 'Data',         field: 'dtexp',        sortable: true,
          format: val => DateFormat(val)
        },
        { name: 'responsible',  align: 'center', label: 'Responsável',  field: 'responsible', sortable: true },
      ],
      rows: [],
      ptBR: locale.date,
      datePicker: null,
      dt_ini: '19000101',
      dt_fim: '30001231',

      //~> PARA O MODAL
      expense: null,
      type: null,
      value: null,
      dtexp: null,
      responsible: null,
      recno: null,
      rebuild: true,

      //~> CONTROLA GRAVAÇÃO vs EDIÇÃO
      editRow: false,
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
  },
  methods: {
    ...mapActions('Functions', [ 'rpc' ]),
    ...mapActions('Querys', [ 'insertData', 'updateData' ]),
    resetForm() { //~> OK
      //~> Fazendo RESET caso o usuário cancele ou entra para preencher o Form
      this.expense = ''
      this.type = ''
      this.value = ''
      this.dtexp = ''
      this.responsible = ''
    },
    validFields() { //~> OK
      if(
        this.expense == '' || this.expense == null ||
        this.value == '' || this.value == null ||
        this.dtexp == '' || this.dtexp == null
      ) {
        this.statusFields = false;
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Favor preencher os campos obrigatórios (Despensa, Valor e Data)'
        });
      } else {
        this.statusFields = true;
      }
    },
    onAdd() { //~> OK
      this.resetForm();
      this.modalExpenses = true;
    },
    onCancel() { //~> OK
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente sair? Ao sair o preenchimento será perdido.',
        persistent: true,
        cancel: true,
      }).onOk(() => {
        this.resetForm();
        this.editRow = false;
        this.modalExpenses = false;
      })
    },
    async onSave() { //~> OK

      this.validFields();
        if(!this.statusFields) return '';

      try {

        let dt = this.dtexp == '' || this.dtexp == null ? 19000101 : String(this.dtexp).replaceAll('-','') //dateToString(this.dtexp)

        if(this.editRow) {//~> OK

          await this.updateData({
            table: 'GE_EXPENSES',
            fields: {
              'GE_DESCRIPTION': this.expense,
              'GE_TYPE': this.type,
              'GE_VALUE': this.value,
              'GE_DATE': dt,
              'GE_RESPONSIBLE': this.responsible,
              'GE_ENTERPRISE': this.enterprise,
              'DELETED': ' ',
            },
            match: {
              'RECNO': this.recno
            }
          })

          Dialog.create({
            title: 'Gestão Eletrônicos',
            message: 'Registro editado com sucesso!'
          });

          this.resetForm();
        } else { //~> OK
          await this.insertData({
            table: 'GE_EXPENSES',
            fields: {
              'GE_DESCRIPTION': this.expense,
              'GE_TYPE': this.type,
              'GE_VALUE': this.value,
              'GE_DATE': dt,
              'GE_RESPONSIBLE': this.responsible,
              'GE_ENTERPRISE': this.enterprise,
              'DELETED': ' ',
            }
          })

          Dialog.create({
            title: 'Gestão Eletrônicos',
            message: 'Pedido incluso com sucesso!'
          });
        }

        this.resetForm();
        this.modalExpenses = false;
        this.editRow = false;
        this.executeQuery();
      } catch (error) {
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: error.message
        });
      }
    },
    onDelete() { //~> OK
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente <span class="text-negative text-bold">EXCLUIR</span> o registro?',
        html: true,
        persistent: true,
        cancel: true,
      }).onOk(async () => {
        //NUNCA EXCLUIREMOS UM REGISTRO, APENAS MARCAMOS NO CAMPO DELETED COMO '*'
        await this.updateData({
          table: 'GE_EXPENSES',
          fields: {
            'DELETED': '*',
          },
          match: {
            'RECNO': this.recno
          }
        })

        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Registro excluído com sucesso!'
        });

        this.modalExpenses = false;
        this.editRow = false;
        this.executeQuery();
      })
    },
    async executeQuery() { //~> OK
      //~> PARA ALIMENTAR A TABELA
      let expenses = await this.rpc({
        function: 'base_expenses',
        enterprise: localStorage.getItem('enterprise'),
        date_start: this.dt_ini,
        date_end: this.dt_fim
      })

      this.rows = expenses.map((exp) => {
        return {
          recno: exp.recno,
          expense: exp.expense,
          type: exp.tp,
          value: exp.val,
          dtexp: exp.dt,
          responsible: exp.resp,
        }
      });

    },
    onDate() { //~> OK
      this.dt_ini = String(this.datePicker.from).replaceAll('/','');
      this.dt_fim = String(this.datePicker.to).replaceAll('/','');

      this.executeQuery();
    },
    onEditRow(_, row) { //~> OK

      this.expense = row.expense
      this.type = row.type
      this.value = row.value
      this.dtexp = DateEnglish(row.dtexp)  //DateFormat(row.dtexp)
      this.responsible = row.responsible
      this.recno = row.recno

      this.editRow = true;
      this.modalExpenses = true;
    },
    onDesp() { //~> OK
      if(this.value < 0) { this.value = 0 }
    },
    exportTable(aTable) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(aTable[0]).join(";"),
        ...aTable.map(item => Object.values(item).join(";"))
        ].join("\n")
        .replace(/(^\[)|(\]$)/gm, "")
        .replaceAll("ç", "c")
        .replaceAll("ã", "a")
        .replaceAll("â", "a")
        .replaceAll("á", "a")
        .replaceAll("à", "a")
        .replaceAll("ê", "e")
        .replaceAll("é", "e")
        .replaceAll("í", "i")
        .replaceAll("õ", "o")
        .replaceAll("ô", "o")
        .replaceAll("ú", "u");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "gestao_Despesas.csv");
      link.click();
    },
  },
  created() {
    defaultColor();
    this.executeQuery();
  }
}
</script>

<style lang="scss">
  @import url('../../globais/GlobalStyles.scss');
</style>